<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          {{ title }}
        </h5>

        <b-form-group id="input-group-clinic-name">
          <label for="input-clinic-name"
            >Nama Klinik <span class="text-danger">*</span></label
          >
          <b-form-input
            id="input-clinic-name"
            v-model="form.clinic_name"
            placeholder="Nama Kllinik"
            readonly
          ></b-form-input>
          <small class="text-danger">{{ error.clinic_name }}</small>
        </b-form-group>

        <template v-if="purpose == 'add'">
        <label for="input-clinic-subdomain"
          >Domain <span class="text-danger">*</span></label
        >
        <b-input-group class="mb-5">
          <template #append>
            <b-input-group-text
              ><span class="">.medhop.id</span></b-input-group-text
            >
          </template>
          <b-form-input
            id="input-clinic-subdomain"
            v-model="form.clinic_subdomain"
            placeholder="Subdomain"
          ></b-form-input>
          <small class="text-danger">{{ error.clinic_subdomain }}</small>
        </b-input-group>
        </template>

        <b-form-group id="input-group-database-name" v-if="purpose == 'edit'">
          <label for="input-database-name"
            >Domain <span class="text-danger">*</span></label
          >
          <b-form-input
            id="input-database-name"
            v-model="form.domain"
            placeholder="Domain"
            :disabled="form.registrant_status == 0 ? false : true"
          ></b-form-input>
          <small class="text-danger">{{ error.domain }}</small>
        </b-form-group>

        <b-form-group id="input-group-database-name">
          <label for="input-database-name"
            >Database Name <span class="text-danger">*</span></label
          >
          <b-form-input
            id="input-database-name"
            v-model="form.database_name"
            placeholder="Database Name"
            :disabled="form.registrant_status == 0 ? false : true"
          ></b-form-input>
          <small class="text-danger">{{ error.database_name }}</small>
        </b-form-group>

        <b-form-group id="input-group-database-ip">
          <label for="input-database-ip"
            >Database IP <span class="text-danger">*</span></label
          >
          <b-form-input
            id="input-database-ip"
            v-model="form.database_ip"
            placeholder="Database IP"
            :disabled="form.registrant_status == 0 ? false : true"
          ></b-form-input>
          <small class="text-danger">{{ error.database_ip }}</small>
        </b-form-group>

        <!-- <b-form-group id="input-group-database-username">
          <label for="input-database-username"
            >Database Username <span class="text-danger">*</span></label
          >
          <b-form-input
            id="input-database-username"
            v-model="form.database_username"
            placeholder="Database Username"
          ></b-form-input>
          <small class="text-danger">{{ error.database_username }}</small>
        </b-form-group>

        <b-form-group id="input-group-database-password">
          <label for="input-database-password"
            >Database Password <span class="text-danger">*</span></label
          >
          <b-form-input
            id="input-database-password"
            v-model="form.database_password"
            placeholder="Database Password"
          ></b-form-input>
          <small class="text-danger">{{ error.database_password }}</small>
        </b-form-group> -->
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/tenants/')"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    purpose: String,
    title: String,
  },
  data() {
    return {
      error: {
        domain: "",
        database_name: "",
        database_username: "",
        database_password: "",
      },
      // other
      isRegion: false,
      passwordIsVisible: "password",
    };
  },
  methods: {
    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/tenants/");
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>